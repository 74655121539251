import { createRouter, createWebHistory } from 'vue-router'

// import register from '../components/RegisterForm.vue'
import apply from '../components/ApplyForm.vue'
import applyv2 from '../components/Apply2Form.vue'
import applyv3 from '../components/Apply3Form.vue'
import editv2 from '../components/Edit2Form.vue'
import history from '../components/HistoryList.vue'
import historyv2 from '../components/Historyv2List.vue'
import historyv2admin from '../components/Historyv2AdminList.vue'
import historyv3 from '../components/Historyv3List.vue'
import edit from '../components/EditForm.vue'
// import reserve from '../components/ReserveCar.vue'
import register from '../components/RegisterForm.vue'

const routes = [{
        path: '/',
        name: 'register',
        component: register,
        meta: {
            title: '註冊會員'
        }
    },
    {
        path: '/applyv2',
        name: 'applyv2',
        component: applyv2,
        meta: {
            title: '電子派車單申請2'
        }
    },
    {
        path: '/apply',
        name: 'apply',
        component: apply,
        meta: {
            title: '電子派車單申請'
        }
    },
    {
        path: '/historyv2',
        name: 'historyv2',
        component: historyv2,
        meta: {
            title: '派車單紀錄2'
        }
    },
    {
        path: '/historyv2admin',
        name: 'historyv2admin',
        component: historyv2admin,
        meta: {
            title: '派車單紀錄-admin'
        }
    },
    {
        path: '/applyv3',
        name: 'applyv3',
        component: applyv3,
        meta: {
            title: '電子派車單申請'
        }
    },
    {
        path: '/historyv3', // 時宜派車單
        name: 'historyv3',
        component: historyv3,
        meta: {
            title: '派車單紀錄'
        }
    },
    {
        path: '/history',
        name: 'history',
        component: history,
        meta: {
            title: '派車單紀錄'
        }
    },
    {
        path: '/edit',
        name: 'edit',
        component: edit,
        meta: {
            title: '檢視個人資料'
        }
    },
    {
        path: '/editv2/:dispatch_id',
        name: 'editv2',
        component: editv2,
        meta: {
            title: '檢視個人資料2'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router