<template>
    <div>
        <van-tabs v-model:active="active" type="card" @click-tab="onClickTab" >
            <van-tab title="今日"></van-tab>
            <van-tab title="最後100筆"></van-tab>
        </van-tabs>
        <van-field name="radio" label="客戶">
            <template #input>
                <van-radio-group v-model="checkedCustomer" direction="horizontal" @change="onCustomerChange">
                <van-radio name="全部">全部</van-radio>
                <van-radio name="佳能">佳能</van-radio>
                <van-radio name="村田">村田</van-radio>
                <van-radio name="京三">京三</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-card
            v-for="(item) in dispatch" :key=item.id
            currency="$"
            :price="item.rental_cost"
            thumb="https://cars.chihlistudio.com/images/app/dispatch_history_image.png"
            
            >
            <template #title>
                <span style="font-size: large; font-weight: 600;">
                    <span style=" color: blue;"> {{ formatDate(item.start_time) }}</span>
                    <div>({{ item.id }}) {{ item.customer_name }}</div>
                </span>
            </template>
            <template #tags>
                <van-tag v-show="item.regular==1" plain type="success">固定</van-tag>
                <van-tag v-show="item.signature_file==null" plain type="danger">沒簽名</van-tag>
            </template>
            <template #desc>
                <!-- <div>派車單號: {{ item.dispatch_id }}</div> -->
                <div>連絡電話: <span style="color: blue;">{{ item.customer_mobile }}</span></div>
                <div>行駛路線: {{ item.route }}</div>
            </template>
            <template #num>
                <span style="font-size: 14px; font-weight: 600;">
                    <!-- <span style="margin-right: 1rem;">{{ formatDate(item.start_time) }}</span> -->
                    <van-button style="margin-right: 3px;" type="success" @click="showEdit(item)">編 輯</van-button>
                    <van-button style="margin-right: 3px;" v-show="item.image_path!=null" type="primary" @click="showDispatch(item.image_path)">顯 示</van-button>
                    <van-button type="danger" @click="delDispatch(item.id)">刪</van-button>
                </span>
            </template>
        </van-card>
        <van-popup round v-model:show="showEditPopUp" closeable position="bottom" :closeOnClickOverlay="false">
            <van-cell-group inset>
                <van-field
                    v-model="select_customer_name"
                    name="select_customer_name"
                    label="客戶名稱"
                    required
                    placeholder="請填寫客戶名稱"
                />
                <van-field
                    v-model="select_customer_mobile"
                    name="select_customer_mobile"
                    label="連絡電話"
                    placeholder="請填寫連絡電話"
                />
                <van-field
                    v-model="select_start_date"
                    name="select_start_date"
                    label="日期"
                    disabled
                    required
                    placeholder="請填寫日期"
                />
                <van-field
                    v-model="select_rental_cost"
                    name="select_rental_cost"
                    label="車資"
                    required
                    placeholder="請填寫車資"
                />
                <van-field
                    v-model="select_route"
                    name="select_route"
                    label="路程"
                    required
                    placeholder="請填寫路線"
                />
                <van-field name="radio" label="固定車班">
                    <template #input>
                        <van-radio-group v-model="selectedRegular" direction="horizontal" @change="onRegularChange">
                        <van-radio :name="0">非固定</van-radio>
                        <van-radio :name="1">固定</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
            </van-cell-group>
            <van-button @click="onSubmit" type="primary" block>
                更 新
            </van-button>
            <!-- <van-signature @submit="onSubmit" @clear="onClear" confirmButtonText="確 認" clearButtonText="清除" /> -->
        </van-popup>
        <van-popup v-model:show="showDispatchPopUp" style="padding-top: 5px; text-align: center;">
            <img height="100%" width="100%" :src="image_path" alt="">
        </van-popup>
        <van-empty v-show="dispatch.length==0" description="目前無派車簽單!" />
    </div>
</template>
<script setup>
// 雅潭一般派車單列表
// 
import{ ref, reactive, onMounted } from 'vue';
import axios from 'axios'
import liff from '@line/liff';
import { showToast, showLoadingToast, closeToast, showConfirmDialog } from 'vant';

let dispatch = ref([]);
let image_path = ref();
// const signature_file = ref('');
let showDispatchPopUp = ref(false);
const showEditPopUp = ref(false);
const select_dispatch_id = ref();
const select_customer_name = ref();
const select_customer_mobile = ref();
const select_start_date = ref();
const select_rental_cost = ref();
const select_route = ref();
const active = ref(0);
const checkedCustomer = ref('全部');
const selectedRegular = ref(0);

// console.log('A0 ', mydata);
let person = reactive({
    'line_id': '',
    'nickname': ''
});
const onCustomerChange = async(value) => {
    console.log('value ', value);
    // console.log('active ', active.value);
    let search = 'today';
    showLoadingToast({
        type: "loading",
        message: "載入中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
    });
    if(active.value == 0) {
        search = 'today'
    }else if(active.value == 1) {
        search = '全部'
    }else{
        search = 'today'
    }
    const payload = reactive({
        "line_id": person.line_id,
        "search": search,
        "customer": checkedCustomer.value
    })
    const response = await axios.post(`https://cars.chihlistudio.com/api/historyv2admin?timestamp=${new Date().getTime()}`, payload)
    // console.log('response ', response.data.data);
    if(response.status !== 200)
    {
        showToast('載入失敗!');
        console.log('response error ', response);
    }else{
        dispatch.value = response.data.data
        // console.log('dispatch ', dispatch.value);
    }
    closeToast();
}
const onClickTab = async (data) => {
    // console.log('onClickTab ', active);
    console.log('data ', data);
    let search = 'today';
    showLoadingToast({
        type: "loading",
        message: "載入中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
    });
    if(active.value == 0) {
        search = 'today'
    }else if(active.value == 1) {
        search = '全部'
    }else{
        search = 'today'
    }
    const payload = reactive({
        "line_id": person.line_id,
        "search": search,
        "customer": checkedCustomer.value
    })
    const response = await axios.post(`https://cars.chihlistudio.com/api/historyv2admin?timestamp=${new Date().getTime()}`, payload)
    // console.log('response ', response.data.data);
    if(response.status !== 200)
    {
        showToast('載入失敗!');
        console.log('response error ', response);
    }else{
        dispatch.value = response.data.data
        // console.log('dispatch ', dispatch.value);
    }
    closeToast();
}
// const onClear = () => {}
const onSubmit = () => {
    // signature_file.value = data.image
    // console.log('signature_file ', signature_file.value);
    showLoadingToast({
        type: "loading",
        message: "載入中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
    });
    axios.post('https://cars.chihlistudio.com/api/updatedata', {
        'dispatch_id': select_dispatch_id.value,
        'line_id': person.line_id,
        'customer_name': select_customer_name.value,
        'customer_mobile': select_customer_mobile.value,
        'start_time': select_start_date.value,
        'rental_cost': select_rental_cost.value,
        'route': select_route.value,
        'regular': selectedRegular.value
    }).then((rs) => {
        // console.log('rs ', rs);
        if(rs.data.success){
            showToast('更新成功!');
            window.location.reload()
        }else{
            showToast('更新失敗!');
        }
    })
    closeToast();
    showEditPopUp.value = false
}
const showEdit = (item) => {
    // console.log('showSignature dispatch_id ', item);
    select_dispatch_id.value = item.dispatch_id
    select_customer_name.value = item.customer_name
    select_customer_mobile.value = item.customer_mobile
    select_rental_cost.value = item.rental_cost
    select_route.value = item.route
    select_start_date.value = item.start_time
    selectedRegular.value = item.regular
    showEditPopUp.value = true
}
const showDispatch = (data) => {
    // console.log('dispatch_id ', data);
    const image_path_data = 'https://cars.chihlistudio.com/' + data;
    const timestamp = new Date().getTime();
    // image_path.value = 'https://e0de0a6e28da.ngrok.app/' + data
    image_path.value = `${image_path_data}?t=${timestamp}`
    // console.log('image_path ', image_path);
    showDispatchPopUp.value = true
}
const delDispatch = (data) => {
    // console.log('delDispatch dispatch_id ', data);
    showConfirmDialog({
        title: '刪除',
        confirmButtonText: '確 認',
        cancelButtonText: '取 消',
        message:
            '確認刪除此筆資料!!',
        })
        .then(() => {
            showLoadingToast({
                type: "loading",
                message: "載入中...",
                forbidClick: true,    
                overlay: true,
                duration: 0,
                loadingType: "spinner"
            });
            axios.post('https://cars.chihlistudio.com/api/deldata', {
                'id': data,
                'line_id': person.line_id
            }).then((rs) => {
                // console.log('rs ', rs);
                if(rs.data.success){
                    showToast('刪除成功!');
                    window.location.reload()
                }else{
                    showToast('刪除失敗!');
                }
            })
            closeToast();
        })
        .catch(() => {
            // on cancel
        });
    
}
const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
onMounted( async () => {
    liff.init({
      liffId: '2005566839-LnkwX5kz', // Use own liffId
      // withLoginOnExternalBrowser: true,
    }).then(function() {
        if (!liff.isLoggedIn()) {
            liff.login({ 
                // 登入後要轉址到哪個網站
                redirectUri:`https://dev.chihlistudio.com/historyv2admin?timestamp=${new Date().getTime()}`
            });
        }else{
            liff.getProfile()
            .then(profile => {
                // console.log('profile ', profile);
                const name = profile.displayName
                person.nickname = name
                person.line_id = profile.userId
                showLoadingToast({
                    type: "loading",
                    message: "載入中...",
                    forbidClick: true,
                    overlay: true,
                    duration: 0,
                    loadingType: "spinner"
                });
                const payload = reactive({
                    "line_id": person.line_id,
                    'search': 'today',
                    'customer': checkedCustomer.value
                })
                // axios.post('https://cars.chihlistudio.com/api/historyv2', payload).then((rs) => {
                axios.post(`https://cars.chihlistudio.com/api/historyv2admin?timestamp=${new Date().getTime()}`, payload).then((rs) => {
                    dispatch.value = rs.data.data
                    closeToast();
                })
            })
            .catch((err) => {
                console.log('error', err);
            });
        }
    
    }).catch(function(error) {
        console.log(error);
    });
    
})
</script>
<style scoped>

</style>